export interface Task {
  id: number;
  name: string;
  description: string;
  acceptedBy: TaskPerformer | undefined;
  assigneeRoles: TaskCommunicationRole[];
  assigneeUsers: TaskPerformer[];
  assigneeCompany: TaskPerformerCompany;
  assigner: TaskPerformer | undefined;
  assignerCompany: TaskPerformerCompany;
  product: TaskProduct | undefined;
  status: TaskStatus;
  taskRole: TaskRole;
  workflowId: number;
  workflowSnapshotId: number;
  accepted: boolean;
  completion: number;
  assignedAt: string;
  emailAccess: TaskEmailAccess[]; // Only if request specifies target emails
}

export type TaskPreview = Pick<
  Task,
  | 'id'
  | 'name'
  | 'acceptedBy'
  | 'assigneeRoles'
  | 'assigneeUsers'
  | 'assigneeCompany'
  | 'assigner'
  | 'assignerCompany'
  | 'product'
  | 'status'
  | 'taskRole'
  | 'completion'
  | 'assignedAt'
  | 'emailAccess'
>;

export interface TaskCommunicationRole {
  id: number;
  key: string;
  title: string;
  description: string | undefined;
  sortOrder: number;
}

export interface TaskPerformer {
  id: number;
  firstName: string;
  lastName: string;
  title: string;
  avatarUrl: string;
}

export interface TaskPerformerCompany {
  id: number;
  name: string;
  logoUrl: string;
}

export interface TaskProduct {
  id: number;
  name: string;
  itemNumber: string;
  gtin: string;
  imageUrl: string | undefined;
}

export interface TaskEmailAccess {
  email: string;
  access: TaskEmailAccessLevel;
}

export enum TaskStatus {
  Completed = 'Completed',
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Closed = 'Closed',
  Deferred = 'Deferred',
  WaitingForConnection = 'WaitingForConnection',
  WaitingForApproval = 'WaitingForApproval',
}

export enum FilterableTaskStatus {
  All = 'All',
  Incomplete = 'Incomplete',
  Completed = 'Completed',
  Closed = 'Closed',
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  NotConnected = 'NotConnected',
  WaitingForApproval = 'WaitingForApproval',
}

export enum TaskRole {
  Assigner = 'Assigner',
  Assignee = 'Assignee',
  AssigneeCollaborator = 'AssigneeCollaborator',
  NotRelated = 'NotRelated',
}

export enum TaskType {
  Received = 'received',
  Sent = 'sent',
}

export enum TaskEmailAccessLevel {
  None = 'NotRelated',
  Assignee = 'Assignee',
  Assigner = 'Assigner',
  AssigneeCollaborator = 'AssigneeCollaborator',
}
